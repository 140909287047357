<script lang="ts">
	import type { ListingSearchResult } from '$lib/types';
	import ListingsRepository from '$lib/repositories/ListingsRepository';
	import Icon from '@iconify/svelte';
	import { getModalStore } from '@skeletonlabs/skeleton';
	import type { SupabaseClient } from '@supabase/supabase-js';
	import { debounce } from 'throttle-debounce';

	export let supabase: SupabaseClient;

	const cBase =
		'card bg-surface-100/60 dark:bg-surface-500/30 backdrop-blur-lg overflow-hidden w-full max-w-[800px] shadow-xl mt-8 mb-auto';
	const cHeader = 'bg-surface-300-600-token flex items-center';
	const cSearchInput = 'bg-transparent border-0 ring-0 focus:ring-0 w-full m-2 ml-4 text-lg';
	const cResults = 'overflow-x-auto max-h-[480px] hide-scrollbar';
	const cResultAnchor =
		'!rounded-none justify-between hover:variant-soft focus:!variant-filled-primary outline-0';
	const cFooter =
		'hidden md:flex items-center gap-2 bg-surface-300-600-token p-4 text-xs font-bold';

	let searchTerm = '';
	let isLoading = false;
	let results: ListingSearchResult[] = [];
	let elemListingSearch: HTMLElement;

	const modalStore = getModalStore();
	const repository = new ListingsRepository(supabase);
	const searchListings = debounce(200, async (query: string) => {
		isLoading = true;
		await repository
			.searchByTitleAndDescription(query)
			.then((data) => {
				results = data;
			})
			.catch((error) => {
				console.error(error);
				results = [];
			})
			.finally(() => {
				isLoading = false;
			});
	});

	function onInput(event: Event): void {
		const target = event.target as HTMLInputElement;
		searchTerm = target.value;
		if (searchTerm.length > 3) {
			searchListings(searchTerm);
		} else {
			results = [];
		}
	}

	function onKeyDown(event: KeyboardEvent): void {
		if (['Enter', 'ArrowDown'].includes(event.code)) {
			const queryFirstAnchorElement = elemListingSearch.querySelector('a');
			if (queryFirstAnchorElement) queryFirstAnchorElement.focus();
		}
	}
</script>

<div bind:this={elemListingSearch} class="modal-search {cBase}">
	<!-- Header -->
	<header class="modal-search-header {cHeader}">
		{#if isLoading}
			<Icon class="text-xl ml-4" icon="eos-icons:bubble-loading" />
		{:else}
			<Icon class="text-xl ml-4" icon="material-symbols:search" />
		{/if}

		<input
			class={cSearchInput}
			bind:value={searchTerm}
			type="search"
			placeholder="Search..."
			on:input={onInput}
			on:keydown={onKeyDown}
		/>
	</header>

	{#if results.length > 0}
		<nav class="list-nav {cResults}" tabindex="-1">
			<ul>
				{#each results as listing}
					<li class="text-lg">
						<a
							class={cResultAnchor}
							href="/listings/{listing.id}"
							on:click={() => {
								modalStore.close();
							}}
						>
							<div class="flex items-center gap-4">
								<Icon icon="material-symbols:arrow-forward-ios-rounded" />
								<span class="flex-auto font-bold opacity-75">{listing.title}</span>
							</div>
							<span class="hidden md:block text-xs opacity-50">{listing.category}</span>
						</a>
					</li>
				{/each}
			</ul>
		</nav>
	{:else}
		<div class="p-4">
			{#if searchTerm.length < 3}
				<p>Start typing to search for Listings.</p>
			{:else}
				<p>No Results found for <code class="code">{searchTerm}</code>.</p>
			{/if}
		</div>
	{/if}

	<footer class="modal-search-footer {cFooter}">
		<div><kbd class="kbd">Esc</kbd> to close</div>
	</footer>
</div>
