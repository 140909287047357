<script lang="ts">
	import '../app.postcss';
	import { AppShell, Modal, type ModalComponent } from '@skeletonlabs/skeleton';
	import { onMount } from 'svelte';
	import { goto, invalidate } from '$app/navigation';
	import { computePosition, autoUpdate, flip, shift, offset, arrow } from '@floating-ui/dom';
	import { storePopup } from '@skeletonlabs/skeleton';
	import BottomNav from '$lib/components/core/BottomNav.svelte';
	import { profileStore } from '$lib/stores';
	import { initializeStores } from '@skeletonlabs/skeleton';
	import ProfilesRepository from '$lib/repositories/ProfilesRepository';
	import { MetaTags } from 'svelte-meta-tags';
	import extend from 'just-extend';
	import { page } from '$app/stores';
	import TopNav from '$lib/components/core/TopNav.svelte';
	import ListingsSearch from '$lib/components/listing/ListingsSearch.svelte';
	import ListingShare from '$lib/components/listing/ListingShare.svelte';

	initializeStores();
	storePopup.set({ computePosition, autoUpdate, flip, shift, offset, arrow });

	export let data;

	let { supabase, session, profile } = data;
	$: ({ supabase, session, profile } = data);

	if (profile !== null) {
		profile.then((data) => profileStore.set(data));
	}

	const modalComponentRegistry: Record<string, ModalComponent> = {
		modalSearch: {
			ref: ListingsSearch,
			props: { supabase: supabase }
		},
		modalShare: {
			ref: ListingShare,
			props: { supabase: supabase }
		}
	};

	onMount(() => {
		const repository = new ProfilesRepository(supabase);
		const { data } = supabase.auth.onAuthStateChange((event, newSession) => {
			if (!newSession && event != 'INITIAL_SESSION') {
				/**
				 * Queue this as a task so the navigation won't prevent the
				 * triggering function from completing
				 */
				setTimeout(() => {
					goto('/', { invalidateAll: true });
				});
			}
			if (newSession?.expires_at !== session?.expires_at) {
				profileStore.set(null);
				invalidate('supabase:auth');
			}
			if (event === 'USER_UPDATED') {
				if (newSession?.user.id) {
					repository.getById(newSession.user.id).then((data) => profileStore.set(data));
				}
			}
		});

		return () => data.subscription.unsubscribe();
	});

	$: metaTags = extend(true, {}, data.baseMetaTags, $page.data.pageMetaTags);
</script>

<MetaTags {...metaTags} />

<Modal components={modalComponentRegistry} />

<AppShell>
	<svelte:fragment slot="header">
		<TopNav isLoggedIn={!!session} />
	</svelte:fragment>

	<div class="container mx-auto p-4">
		<slot />
	</div>

	<svelte:fragment slot="footer">
		<BottomNav isLoggedIn={!!session} />
	</svelte:fragment>
</AppShell>
