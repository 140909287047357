<script lang="ts">
	import Icon from '@iconify/svelte';
	import { page } from '$app/stores';
	import { previousPage } from '$lib/stores';
	import { goto } from '$app/navigation';

	let canNavigateBack = false;
	$: canNavigateBack = $page.url.pathname !== '/' && $previousPage !== null;

	function navigateBack() {
		if ($previousPage !== null) {
			goto($previousPage);
		}
	}
</script>

{#if canNavigateBack}
	<button on:click={navigateBack}>
		<Icon height="auto" icon="material-symbols:arrow-left-alt" />
	</button>
{/if}
